import React from 'react';
import {
    Box,
    Heading
} from '@chakra-ui/react'

//import SummerScene from './SummerScene';
import SummerSceneNoMotion from './SummerSceneNoMotion';

export default function FallBanner(props) {
    return (
        <Box position="relative" bg="#0069a6">
          <Heading as="h1" variant="h1" textAlign="center" color="white" pt="12" mb="0" px="8">
               {props.headline}
          </Heading>
           <Box w="100%">
               <SummerSceneNoMotion />
           </Box>
        </Box>
    );
}